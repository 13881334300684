import React from 'react'
import PropTypes from 'prop-types'
import logo from '../assets/images/logo.webp'


const Header = (props) => (
    <header id="header" className="alt">
                        <img src={logo} alt="logo" width="250px" height="47px"/>
                 
    </header>
)

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header

import React, { useState, useEffect } from "react";
import axios from "axios";
import { validateFormData } from "../utils/validation/validationEmail";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
// import { LocationSearchInput } from "./locationSearchInput";
import LocationInput from "./locationInput";

const Contact = (props) => {
  const data = {
    name: "",
    email: "",
    address: "",
    message: "",
    houseNumber: null,
    streetName: "",
    city: "",
    country: "",
    postalCode: "",
    additionalAddress: "",
    recipientName: "",
  };
  const validationData = {
    nameValidation: "",
    emailValidation: "",
    addressValidation: "",
    messageValidation: "",
    houseNumberValidation: null,
    streetNameValidation: "",
    cityValidation: "",
    countryValidation: "",
    postalCodeValidation: "",
    additionalAddressValidation: "",
    recipientNameValidation: "",
  };
  const [formData, setFormData] = useState(data);
  const [isCheckBox, setIsCheckBox] = useState(false);

  const [validation, setValidation] = useState(validationData);
  const [submissionsTotal, setSubmissionsTotal] = useState(0);
  const [successfully, setSuccessfully] = useState(null);
  const {
    name,
    email,
    address,
    message,
    houseNumber,
    streetName,
    city,
    state,
    country,
    postalCode,
    additionalAddress,
    recipientName,
  } = formData;
  const {
    nameValidation,
    emailValidation,
    addressValidation,
    messageValidation,
    houseNumberValidation,
    streetNameValidation,
    cityValidation,
    countryValidation,
    postalCodeValidation,
    additionalAddressValidation,
    recipientNameValidation,
  } = validation;
  useEffect(async () => {
    const submissions = await axios.get(
      `${process.env.GATSBY_API_BACK_END}/submissions?_limit=-1`
    );
    if (submissions) setSubmissionsTotal(submissions.data.length);
  }, []);
  const handleChange = (e, filedData, filedValidation) => {
    setFormData({
      ...formData,
      [filedData]: e,
    });
    setValidation({
      ...validation,
      [filedValidation]: validateFormData(filedData, e),
    });
  };
  const handleChangeDetailAddress = (obj) => {
    setFormData({
      ...formData,
      houseNumber: obj.houseNumber,
      streetName: obj.streetName,
      city: obj.city,
      state: obj.state,
      country: obj.country,
      postalCode: obj.postalCode,
      address: `${obj.houseNumber ? `${obj.houseNumber} ` : ""}${
        obj.streetName ? `${obj.streetName}` : ""
      }`,
    });
    setValidation(validationData);
  };

  const toastShow = (text, bg) => {
    toast(text, {
      closeOnClick: false,
      style: {
        background: bg,
        color: "white ",
        fontWeight: "bold",
      },
      autoClose: true,
      closeButton: false,
    });
  };
  const submit = async () => {
    setIsCheckBox(!isCheckBox);
    let submitForm = formData;
    if (formData.state) {
      submitForm = { ...formData, city: `${formData.city}, ${formData.state}` };
    }
    try {
      await axios
        .post(`${process.env.GATSBY_API_BACK_END}/submissions`, submitForm)
        .then((res) => {
          if (res.data.error) {
            toastShow(res.data.error, "#B82C16");
          } else {
            setSuccessfully("You will receive an email shortly. Thank you!");
          }
        });
    } catch (error) {
      console.log(error, "error11");
    }
    setIsCheckBox(!isCheckBox);
  };
  const checkIsSubmit = () => {
    const { additionalAddress, state, ...newObj } = formData;
    const isEmpty = Object.values(newObj).some((x) => x === null || x === "");
    return !isCheckBox || isEmpty || emailValidation;
  };
  return (
    <>
      <ToastContainer />

      <section id="contact">
        <div className="inner">
          <section>
            {successfully ? (
              <div className="success">
                <p>{successfully}</p>
              </div>
            ) : (
              <form method="post">
                <div className="field half first">
                  <label>Your Name</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) =>
                      handleChange(e.target.value, "name", "nameValidation")
                    }
                  />
                  <span className="error">{nameValidation}</span>
                </div>
                <div className="field half">
                  <label htmlFor="email">Your email</label>
                  <input
                    onChange={(e) =>
                      handleChange(e.target.value, "email", "emailValidation")
                    }
                    value={email}
                    type="text"
                  />
                  <div className="error">
                    <span>{emailValidation}</span>
                  </div>
                </div>
                <div className="field">
                  <label>Recipient Name</label>
                  <input
                    type="text"
                    onChange={(e) =>
                      handleChange(
                        e.target.value,
                        "recipientName",
                        "recipientNameValidation"
                      )
                    }
                    value={recipientName}
                    rows="6"
                  ></input>
                  <span className="error">{recipientNameValidation}</span>
                </div>
                <div className="field">
                  <label htmlFor="Address">Address(Line 1)</label>
                  {/* <textarea Name="Address" id="Address" rows="3"></textarea> */}
                  {/* <LocationSearchInput
                    handleChangeFormData={handleChange}
                    address={address}
                  /> */}
                  <LocationInput
                    handleChangeFormData={handleChange}
                    handleChangeDetailAddress={handleChangeDetailAddress}
                    addressValue={address}
                  />
                  <span className="error">{addressValidation}</span>
                </div>

                <div className="field">
                  <label htmlFor="additionalAddress">Address(Line 2)</label>
                  <input
                    onChange={(e) =>
                      handleChange(e.target.value, "additionalAddress", null)
                    }
                    value={additionalAddress}
                    type="text"
                  />
                </div>

                <div className="field half first">
                  <label>Postal Code</label>
                  <input
                    type="text"
                    value={postalCode}
                    onChange={(e) =>
                      handleChange(
                        e.target.value,
                        "postalCode",
                        "postalCodeValidation"
                      )
                    }
                  />
                  <span className="error">{postalCodeValidation}</span>
                </div>
                <div className="field half">
                  <label>city</label>
                  <input
                    type="text"
                    value={city}
                    onChange={(e) =>
                      handleChange(e.target.value, "city", "cityValidation")
                    }
                  />
                  <span className="error">{cityValidation}</span>
                </div>

                <div className="field half first">
                  <label>STATE / PROVINCE</label>
                  <input
                    type="text"
                    value={state}
                    onChange={(e) =>
                      handleChange(e.target.value, "state", "stateValidation")
                    }
                  />
                  <span className="error">{cityValidation}</span>
                </div>
                <div className="field half">
                  <label htmlFor="email">Country</label>
                  <input
                    onChange={(e) =>
                      handleChange(
                        e.target.value,
                        "country",
                        "countryValidation"
                      )
                    }
                    value={country}
                    type="text"
                  />
                  <div className="error">
                    <span>{countryValidation}</span>
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="Message">
                    Message (270 character maximum) / Unless it's from a secret
                    admirer, remember to include your name!
                  </label>

                  <textarea
                    onKeyPress={(e) => {
                      if (e.key === "Enter") e.preventDefault();
                    }}
                    onChange={(e) =>
                      handleChange(
                        e.target.value,
                        "message",
                        "messageValidation"
                      )
                    }
                    value={message}
                    rows="6"
                  ></textarea>
                  <span className="error">{messageValidation}</span>
                </div>
                <div className="mb-5">
                  <input
                    onClick={(e) => setIsCheckBox(!isCheckBox)}
                    checked={isCheckBox}
                    type="checkbox"
                    id="terms"
                    name="terms"
                  />
                  <label htmlFor="terms">
                    I agree to the{" "}
                    <a target="blank" href="/toc">
                      terms and conditions
                    </a>
                    , and{" "}
                    <a target="blank" href="/privacy">
                      privacy policy
                    </a>
                    . INKIII does not use cookies or retain any of your private
                    data.
                  </label>
                </div>
                <ul className="actions">
                  <li>
                    <button
                      disabled={checkIsSubmit()}
                      onClick={submit}
                      type="button"
                      className="special"
                    >
                      Send INKIII Mail
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        setFormData(data);
                        setValidation(validationData);
                        setIsCheckBox(false);
                      }}
                    >
                      Clear
                    </button>
                  </li>
                </ul>
                <ul className="submission">
                  <span>
                    {" "}
                    {Number(250 - submissionsTotal)} submission(s) left
                  </span>
                </ul>
              </form>
            )}
          </section>
          <section className="split">
            <section className="spotlights">
              <div className="content">
                <div className="contact-method">
                  <h1> 250 postcards available </h1>
 <p><strong>
                    INKIII is a non-profit platform that supports emerging
                    artists.</strong> 
                  </p>

 <p><strong>
Write your message in the form, and we’ll mail the physical postcard to your destination via regular mail, for free. Each limited edition card is sent by regular mail from
                    Rome, Italy.
                    </strong> </p>
                  

                  <p>
                    <strong> About the artist: </strong>
Fabiana Trudu is a photographer from Sardinia based in Lisbon. She has a background in Landscape and Urban Design and is passionate about visual and performing arts. She enjoys undertaking visual research through elements that inspire her: landscape, architecture, people, and most especially the sea. She is constantly seeking out the Beauty that surrounds her. 
                  </p>

                  <p>
                    <a target="blank" href="https://fabianatrudu.wixsite.com/website">
                     fabianatrudu.com
                    </a>
                    <br />
                  </p>
                 
  
                </div>
              </div>
            </section>
          </section>
        </div>
      </section>
    </>
  );
};

export default Contact;

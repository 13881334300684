import React from 'react'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
    
            <ul className="copyright">
                <li>&copy; INKIII</li><li><a target="blank" href="/toc">Terms and Conditions</a></li>
     <li>For all inquiries, hello@inkiii.com</li>
                    <li><a href="http://www.instagram.com/inkiiimail" className="icon alt fa-instagram"></a></li>

            </ul>
   
        </div>
    </footer>
)

export default Footer

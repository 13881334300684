import React, { useEffect, useRef, useState } from "react";

const apiKey = process.env.GATSBY_GOOGLE_PLACES_API_KEY;
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

// load google map api js

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

function LocationInput({
  handleChangeFormData,
  handleChangeDetailAddress,
  addressValue,
}) {
  const searchInput = useRef(null);
  const [address, setAddress] = useState({});

  const extractAddress = (place) => {
    const address = {
      city: "",
      postalCode: "",
      country: "",
      houseNumber: "",
      streetName: "",
    };

    if (!Array.isArray(place?.address_components)) {
      return address;
    }
    place.address_components.map((component) => {
      const types = component.types;
      const value = component.long_name;

      if (types.includes("street_number")) {
        address.houseNumber = value;
      }

      if (types.includes("route")) {
        address.streetName = value;
      }

      if (types.includes("locality")) {
        address.state = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }

      if (types.includes("administrative_area_level_2")) {
        address.city = value;
      }

      if (types.includes("postal_code")) {
        address.postalCode = value;
      }

      if (types.includes("country")) {
        address.country = value;
      }
    });
    return address;
  };

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    searchInput.current.value = "Getting your location...";
    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        const place = location.results[0];
        const _address = extractAddress(place);
        setAddress(_address);
        searchInput.current.value = _address.plain();
      });
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        reverseGeocode(position.coords);
      });
    }
  };

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() =>
      initAutocomplete({ handleChangeFormData, address })
    );
  }, []);
  useEffect(() => {
    handleChangeDetailAddress(address);
  }, [address]);

  return (
    <div className="App">
      <div>
        <div className="search">
          <input
            value={addressValue}
            ref={searchInput}
            type="text"
            placeholder="Search location...."
            onChange={(e) =>
              handleChangeFormData(
                e.target.value,
                "address",
                "addressValidation"
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default LocationInput;

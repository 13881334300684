export function validateFormData(fileData, value) {
  if (fileData === "email") {
    const re = /\S+@\S+\.\S+/
    return re.test(String(value).toLowerCase()) ? "" : ` Invalid ${fileData} `;
  } else if (fileData === "message") {
    return value
      ? value.length > 270
        ? "Maximum 270 characters."
        : ""
      : checkUpper(fileData);
  } else {
    return value ? "" || null : checkUpper(fileData);
  }
}

function checkUpper(str) {
  var index = 0;
  for (var i = 0; i < str.length; i++) {
    if (/[A-Z]/.test(str[i])) {
      index = i;
      break;
    }
  }
  if (str === "additionalAddress") {
    return "Additional Address Line is required";
  } else {
    return `${str.charAt(0).toUpperCase()}${str.slice(1, i)}  ${str.slice(
      i
    )} is required`;
  }
}
